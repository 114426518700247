<template lang="pug">
  article.infos.fixed.overlay.overflow-y-screen.flex.flex-col.text-navy.font-messina.leading-tight.px-8.lg_px-20
    section.flex-1.w-full.flex.flex-col.justify-end
      transition(name="infopage", mode="out-in")
        router-view(:key="$route.path")

    footer-links.sticky.bottom-0.left-0.w-full.flex.items-start.lg_pt-20(v-if="main")
      app-link.inline-block.mr-10.md_mr-20.py-3.-my-3(v-for="(row, i) in main.data.menu_footer_links", :field="row.link", :key="i")
        span.text-navy {{ row.link_text }}
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Info',
  computed: {
    ...mapGetters(['main'])
  },
  created () {
    this.$store.commit('setUIColor', 'navy')
  }
}
</script>

<style>
.infos nav{
  & .router-link-active{
    border-bottom:1px solid var(--red);
  }
}

.infopage-leave-active{ transition: opacity 300ms; }
.infopage-enter-active{ transition: opacity 500ms 300ms }
.infopage-enter, .infopage-leave-to{ opacity:0; }
</style>
