<template lang="pug">
  article.info(v-if="doc")
    template(v-for="slice in doc.data.body")
      .mt-lh-tight(v-if="slice.slice_type === 'info_listing'")
        rich-text.text-xs(:field="slice.primary.heading", style="line-height: calc(22/12 * 1em)")
        rich-text.children-mt-lh-tight.bold-is-red(:field="slice.primary.text")
</template>

<script>
export default {
  name: 'Info',
  computed: {
    doc () {
      return this.$store.state.docs.find(doc => doc.uid === this.$route.params.info)
    }
  }
}
</script>

<style>
.info > div a{
  &:after{
    content:'';
    display: inline-block;
    margin-left: calc(8/16 * 1em);
    margin-bottom: calc(1/16 * 1em);
    background:url(~@/assets/icons/right-arrow-short--navy.svg);
    width:calc(36.5/16 * 1em);
    height:calc(8/16 * 1em);
  }
}
</style>
